@-webkit-keyframes bounce {
	0% {
		transform: translateY(-5px);
	}

	50% {
		transform: translateY(10px);
	}

	100% {
		transform: translateY(-5px);
	}
}

@keyframes bounce {
	0% {
		transform: translateY(-5px);
	}

	50% {
		transform: translateY(10px);
	}

	100% {
		transform: translateY(-5px);
	}
}

@keyframes bounceWidth {
	0% {
		width: 250px;
	}

	50% {
		width: 300px;
	}

	100% {
		width: 250px;
	}
}

@-webkit-keyframes bounceWidth {
	0% {
		width: 250px;
	}

	50% {
		width: 300px;
	}

	100% {
		width: 250px;
	}
}