@-webkit-keyframes animate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}

}

@keyframes animate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}

}

/* .Projects-item {

	position: relative;
	width: 250px;
	height: 250px;
	border-radius: 50%;
	background: black;
}

.Projects-item:before{
	content: '';
	position: absolute;
	inset: -10px 140px;
	background: linear-gradient(315deg, #00ccff, #d400d4);
	transition: 0.5s;
	animation: animate 4s linear infinite;
} */