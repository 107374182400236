html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

} */

/* .font-link {
  font-family: 'Hanalei Fill', cursive;
} */