@-webkit-keyframes bounce {
	0% {
		transform: translateY(-5px);
	}

	50% {
		transform: translateY(10px);
	}

	100% {
		transform: translateY(-5px);
	}
}

@keyframes bounce {
	0% {
		transform: translateY(-5px);
	}

	50% {
		transform: translateY(10px);
	}

	100% {
		transform: translateY(-5px);
	}
}
