.wpo-footer-area {
    background: #142440;
}

.wpo-footer-top {
    padding: 48px 0;
}

.wpo-footer-logo {
    margin-bottom: 30px;
}

.social {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    list-style-type: none;
}

.social li a {
    display: block;
    height: 45px;
    width: 45px;
    background: #fff;
    color: #333;
    line-height: 51px;
    border-radius: 50%;
    text-align: center;
    margin: 0 10px;
    transition: all 0.3s;
    margin-top: 5px;
}

.wpo-footer-top p {
    color: #fff;
    margin-bottom: 30px;
}

.wpo-footer-top a {
    color: #fff;
}

.instagram ul {
    flex-wrap: wrap;
    margin: 0px -3px;
}

.instagram ul li {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 5px;
    padding: 0px 3px;
}

.instagram ul li img {
    width: 100%;
}

.footer-widget.instagram {
    margin-top: 0;
}

.footer-widget.instagram h3 {
    font-size: 25px;
    margin-bottom: 30px;
}

.footer-link ul li a {
    padding-top: 10px;
    display: block;
}

.wpo-footer-top h3 {
    font-size: 25px;
    color: #fff;
    margin-bottom: 20px;
}

.wpo-footer-bottom {
    background: #101e35;
    padding: 30px 0;
}

.wpo-footer-bottom-content {
    text-align: center;
    margin-top: 32px;
    color: #fff;
    font-size: 16px;
    text-align: center;
}